import {
  AssignmentsRequest,
  ArchiveRequest,
  AssignmentsResponse,
  GetAssignmentActivityRequest,
  GetAssignmentActivityResponse,
  CreateResponseForStudentRequest,
  GetAllStudentResponsesForInstructorRequest,
  GetAllStudentResponsesForInstructorResponse,
  GetPreviewResponseResponse,
  GetStudentResponseResponse,
  GetResponseRequest,
  GetResponseResponse,
  GetStudentResponseRequest,
  GradeAssignmentResponseRequest,
  ResetAssignmentResponsesRequest,
  BulkGradeAssignmentResponsesRequest,
  BulkResetSubmissionsRequest,
  UpdateAssignmentRequest,
  UpdateAssignmentResponsesRequest,
  SubmitComponentResponseResponse,
  SubmitPreviewComponentResponseResponse,
  SubmitPreviewComponentResponseRequest,
  AnswerAssignmentResponseRequest,
  SubmitAssignmentResponseRequest,
  SubmitComponentResponseRequest,
  ExtendSubmissionsRequest,
  CancelExtensionsRequest,
  SubmitAssignmentPreviewResponseRequest,
  BulkRegradeSubmissionsRequest
} from '@pi/api-types'

import Client from './client'
export default class Class {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async archive(request: ArchiveRequest): Promise<void> {
    const params = JSON.stringify({ isArchived: request.isArchived })
    await this.client.put({
      path: `/api/v3/assignments/${request.assignmentId}/archive`,
      body: params
    })
  }

  async delete(request: AssignmentsRequest): Promise<void> {
    await this.client.delete({
      path: `/api/v3/assignments/${request.assignmentId}`
    })
  }

  async get(request: AssignmentsRequest): Promise<AssignmentsResponse | void> {
    const { body } = await this.client.get({
      path: `/api/v3/assignments/${request.assignmentId}`
    })
    return body
  }

  async getActivity({
    assignmentId
  }: GetAssignmentActivityRequest): Promise<GetAssignmentActivityResponse> {
    const { body } = await this.client.get<GetAssignmentActivityResponse>({
      path: `/api/v3/assignments/${assignmentId}/activity`
    })
    return body
  }
  async createStudentResponses(
    request: CreateResponseForStudentRequest
  ): Promise<void> {
    await this.client.post({
      path: `/api/v3/assignments/${request.assignmentId}/responses`
    })
  }

  async getAllResponses(
    request: GetAllStudentResponsesForInstructorRequest
  ): Promise<GetAllStudentResponsesForInstructorResponse> {
    const { assignmentId, components, ...rest } = request
    const params = new URLSearchParams(rest as any)
    if (components) {
      components.forEach(c => params.append('components[]', c))
    }
    const { body } =
      await this.client.get<GetAllStudentResponsesForInstructorResponse>({
        path: `/api/v3/assignments/${assignmentId}/responses?${params}`
      })
    return body
  }

  async getPreviewResponse(
    request: AssignmentsRequest
  ): Promise<GetPreviewResponseResponse> {
    const { body } = await this.client.get<GetPreviewResponseResponse>({
      path: `/api/v3/assignments/${request.assignmentId}/preview-response`
    })
    return body
  }

  async getStudentResponse(
    request: GetStudentResponseRequest
  ): Promise<GetStudentResponseResponse> {
    const query = request.componentIds
      ?.map(id => `componentIds[]=${id}`)
      .join('&')
    const { body } = await this.client.get<GetStudentResponseResponse>({
      path: `/api/v3/assignments/${request.assignmentId}/response?${query}`
    })
    return body
  }

  async getResponseById(
    request: GetResponseRequest
  ): Promise<GetResponseResponse> {
    let path = `/api/v3/assignments/${request.assignmentId}/response/${request.responseId}`
    if (request.sort) {
      path += `?sort=${request.sort.field}&dir=${request.sort.dir}`
    }
    const { body } = await this.client.get<GetResponseResponse>({
      path
    })
    return body
  }

  async gradeResponse(request: GradeAssignmentResponseRequest): Promise<void> {
    const { assignmentId, id, ...updatedResponseFields } = request
    await this.client.patch({
      path: `/api/v3/assignments/${assignmentId}/response/${id}`,
      body: JSON.stringify(updatedResponseFields)
    })
    return
  }

  async unsubmitResponse(
    request: GradeAssignmentResponseRequest
  ): Promise<void> {
    const { assignmentId } = request
    await this.client.post({
      path: `/api/v3/assignments/${assignmentId}/responses/unsubmit`
    })
    return
  }

  async saveResponse(request: AnswerAssignmentResponseRequest): Promise<void> {
    const { assignmentId, components, lockSections } = request
    const { body } = await this.client.patch({
      path: `/api/v3/assignments/${assignmentId}/response`,
      body: JSON.stringify({
        components,
        lockSections
      })
    })
    return body
  }

  async submit(request: SubmitAssignmentResponseRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/assignments/${request.assignmentId}/response/submit`,
      body: JSON.stringify({
        responses: request.responses
      })
    })
    return
  }

  async submitPreviewResponses(
    request: SubmitAssignmentPreviewResponseRequest
  ): Promise<GetPreviewResponseResponse> {
    const { body } = await this.client.post<GetPreviewResponseResponse>({
      path: `/api/v3/assignments/${request.assignmentId}/preview-response/submit`,
      body: JSON.stringify({
        responses: request.responses,
        variables: request.variables
      })
    })
    return body
  }

  async resetResponse(request: ResetAssignmentResponsesRequest): Promise<void> {
    await this.client.patch({
      path: `/api/v3/assignments/${request.assignmentId}/responses/${request.responseId}/reset`
    })
    return
  }

  async saveResponsesBulk(
    request: BulkGradeAssignmentResponsesRequest
  ): Promise<void> {
    await this.client.patch({
      path: `/api/v3/assignments/${request.assignmentId}/responses/bulk`,
      body: JSON.stringify(request)
    })
  }

  async resetSubmissionsBulk(
    request: BulkResetSubmissionsRequest
  ): Promise<void> {
    const { assignmentId, assignmentResponses } = request
    await this.client.post({
      path: `/api/v3/assignments/${assignmentId}/responses/reset-submissions`,
      body: JSON.stringify({
        assignmentResponses
      })
    })
    return
  }

  async update(request: UpdateAssignmentRequest): Promise<void> {
    await this.client.patch({
      path: `/api/v3/assignments/${request.assignmentId}`,
      body: JSON.stringify(request)
    })
  }

  async updateResponses(
    request: UpdateAssignmentResponsesRequest
  ): Promise<void> {
    await this.client.put({
      path: `/api/v3/assignments/${request.assignmentId}/responses`,
      body: JSON.stringify(request)
    })
  }

  async regrade(request: BulkRegradeSubmissionsRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/assignments/${request.assignmentId}/regrade`,
      body: JSON.stringify(request)
    })
    return
  }

  async submitComponentResponse(
    request: SubmitComponentResponseRequest
  ): Promise<SubmitComponentResponseResponse> {
    const { body } = await this.client.post<SubmitComponentResponseResponse>({
      path: `/api/v3/assignments/${request.assignmentId}/response/component/${request.componentId}`,
      body: JSON.stringify(request)
    })
    return body
  }

  async submitPreviewComponentResponse(
    request: SubmitPreviewComponentResponseRequest
  ): Promise<SubmitComponentResponseResponse> {
    const { body } =
      await this.client.post<SubmitPreviewComponentResponseResponse>({
        path: `/api/v3/assignments/${request.assignmentId}/preview-response/component/${request.componentId}`,
        body: JSON.stringify(request)
      })
    return body
  }

  async downloadGradesAsCSV(request: AssignmentsRequest): Promise<ArrayBuffer> {
    const { body } = await this.client.get<ArrayBuffer>({
      path: `/api/v3/assignments/${request.assignmentId}/csv-grades`
    })
    return body
  }
  async extendAssignment({
    assignmentId,
    ...request
  }: ExtendSubmissionsRequest): Promise<void> {
    const body = JSON.stringify(request)
    await this.client.post({
      path: `api/v3/assignments/${assignmentId}/extend`,
      body
    })
  }

  async cancelExtensions({
    assignmentId,
    ...request
  }: CancelExtensionsRequest): Promise<void> {
    const body = JSON.stringify(request)

    await this.client.post({
      path: `api/v3/assignments/${assignmentId}/cancel-extensions`,
      body
    })
  }
}
