<template>
  <form-group class="mb-8">
    <form-label id="total-points">Hints And Feedback</form-label>
    <div>
      <UISwitch v-model="showHints" name="showHints">Show Hints</UISwitch>
      <div class="flex my-3">
        <label for="studentFeedbackTiming">Show Feedback to Students</label>
        <select-field
          id="studentFeedbackTiming"
          v-model="studentFeedbackTiming"
          class="ml-4"
        >
          <option
            :value="AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit"
          >
            After Submitting Each Question
          </option>
          <option
            :value="AssignmentStudentFeedbackTimingValue.AfterAssignmentSubmit"
          >
            After Submitting the Assignment
          </option>
        </select-field>
      </div>
    </div>
  </form-group>
</template>

<script setup lang="ts">
import { UISwitch } from '@pi/design'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'
import { computed } from 'vue'

const props = defineProps<{ noHints: boolean }>()
const emit = defineEmits<{ 'update:noHints': [boolean] }>()

const showHints = computed({
  get: () => !props.noHints,
  set: value => emit('update:noHints', !value)
})
const studentFeedbackTiming = defineModel<AssignmentStudentFeedbackTimingValue>(
  'studentFeedbackTiming'
)
</script>
