<template>
  <auth-card header="Connect Account">
    <loading-container :loading="isloading">
      <p>
        You have an existing Pivot Interactives account with the same email.
        <span v-if="replacingProvider">
          If you wish to use {{ oAuthServiceDisplay }} instead of
          {{ replacingProvider }} to login to your Pivot Interactives account,
          you must first authenticate with {{ replacingProvider }}. After which
          you will only be able to log in with your
          {{ oAuthServiceDisplay }} account.
        </span>
        <span v-else>
          Enter your password to connect your {{ oAuthServiceDisplay }} account
          to your Pivot Interactives account. Going forward, you will only be
          able to log in with your {{ oAuthServiceDisplay }} account.
        </span>
      </p>
      <async-form @submit="onSubmit" class="connect-sso__form">
        <form-group>
          <form-label id="email-label"> Email address </form-label>
          <static-value aria-labelledby="email-label">{{
            formData.email
          }}</static-value>
        </form-group>
        <template v-if="!replacingProvider">
          <form-group>
            <form-label for="password"> Password </form-label>
            <text-input
              id="password"
              v-model="formData.password"
              rules="required"
              label="Password"
              type="password"
              autocomplete="current-password"
            />
          </form-group>

          <form-group class="submit-group">
            <submit-button>
              <template #default>Connect Accounts</template>
              <template #submitting>Connecting Accounts</template>
              <template #submitted>Accounts Connected</template>
            </submit-button>
          </form-group>
        </template>
        <template v-else>
          <oauth-selection
            @success="onSubmit"
            prefix="Login with"
            :allowed="formData.replacingProvider"
          ></oauth-selection>
        </template>
      </async-form>
    </loading-container>
  </auth-card>
</template>

<script setup>
import AuthCard from './AuthCard.vue'
import client from 'src/shared/api-client'
import { computed, onBeforeMount, ref, reactive } from 'vue'
import { useFlash } from 'src/shared/hooks/flash'
import { useRouter } from 'vue-router'
import OauthSelection from './OauthSelection.vue'

const router = useRouter()
const flash = useFlash()

const formData = reactive({
  email: '',
  password: '',
  provider: ''
})

const isloading = ref(true)

onBeforeMount(async () => {
  await getOAuthProfile()
})

const oAuthServiceDisplay = computed(() => {
  switch (formData.provider) {
    case 'google':
      return 'Google'
    case 'clever':
      return 'Clever'
    case 'classLink':
      return 'ClassLink'
    case 'azure':
      return 'Microsoft'
    default:
      return 'third party service'
  }
})

const replacingProvider = computed(() => {
  if (!formData.replacingProvider) return
  switch (formData.replacingProvider) {
    case 'google':
      return 'Google'
    case 'clever':
      return 'Clever'
    case 'classLink':
      return 'ClassLink'
    case 'azure':
      return 'Microsoft'
    default:
      return false
  }
})

async function onSubmit(e) {
  try {
    await client.auth.connectOAuthAccount(formData.password)
    router.push({
      ...router.currentRoute.value,
      params: { ...router.currentRoute.value.params, step: 'redirect' }
    })
    if (e) e.done()
  } catch (error) {
    if (e) e.done(false)
    if (error.status === 401) {
      flash.error(error.message)
    } else {
      throw error
    }
  }
}
async function getOAuthProfile() {
  isloading.value = true
  const response = await client.auth.getOAuthProfile()
  formData.provider = response.provider
  formData.email = response.email
  formData.replacingProvider = response.oldProvider
  isloading.value = false
}
</script>

<style scoped>
.submit-group {
  display: flex;
  justify-content: flex-end;
}

.connect-sso__form {
  width: 300px;
  margin: auto;
}
</style>
