<template>
  <form-group>
    <form-label id="attempts">Total Possible Points</form-label>
    <div>
      <UISwitch name="OverridePointsTotal" v-model="override" :disabled="false">
        Override Point Total
      </UISwitch>
    </div>
    <div class="flex pt-6" v-if="override">
      <number-input
        id="overrideAttempts"
        class="w-20"
        v-model="overrideTotal"
        label="Total number of attempts for all questions"
      />
      <label for="overrideAttempts" class="font-normal pl-4 leading-[2.4]"
        >Total number of points for the activity.</label
      >
    </div>
  </form-group>
</template>

<script setup lang="ts">
import { AssignmentsResponse } from '@pi/api-types'
import { computed, ref, watch } from 'vue'
import { UISwitch } from '@pi/design'

interface AssignmentPointsOverrideProps {
  assignment?: AssignmentsResponse
}
const props = defineProps<AssignmentPointsOverrideProps>()
const emit = defineEmits(['update'])

const totalPointsValue = ref(props.assignment?.totalPointValue)

const override = ref(
  props.assignment ? !!props.assignment.totalPointValue : false
)
const overrideTotal = computed({
  get: () => props.assignment?.totalPointValue,
  set: val => {
    emit('update', override.value ? val : undefined)
    totalPointsValue.value = val
  }
})

const updated = () => {
  emit('update', override.value ? overrideTotal.value : undefined)
}
</script>
