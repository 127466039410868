import { substituteVariablesMathjs } from './activity-variables'
import { evaluateExpression } from '../components/editor/expression/Expression'

export const processLatex = (match, variableContext = [], replaceField) => {
  if (!match) return match
  const foundvars = findExpressions(match)

  let updatedFormula = match
  foundvars.forEach(item => {
    if (item && item !== '') {
      const value = substituteVariablesMathjs(
        item,
        variableContext,
        replaceField
      )
      updatedFormula = updatedFormula.replace(
        `\\expr{${item}}`,
        `\\expr{${value}}`
      )
    }
  })
  return updatedFormula
}

export const getLatexForDisplay = ({ variables = [], formula }) => {
  const foundvars = findExpressions(formula)
  let updatedFormula = formula

  foundvars.forEach(item => {
    const value = evaluateExpression(item, variables)
    const replacement =
      value !== null && value !== undefined && value !== ''
        ? value
        : '\\scriptstyle\\text{Student Response}'
    updatedFormula = updatedFormula.replace(`\\expr{${item}}`, replacement)
  })

  return updatedFormula
}

export const getVariablesFromLatex = ({ formula, variables = [] }) => {
  const expressions = findExpressions(formula)
  const usedVariables = variables.filter(v =>
    expressions.some(expression => expression.includes(v.id))
  )
  return usedVariables ?? []
}

export function expressionToBoxedLatex(latex) {
  const foundvars = findExpressions(latex)

  let updatedFormula = latex
  foundvars.forEach(item => {
    updatedFormula = updatedFormula.replace(
      `\\expr{${item}}`,
      '\\boxed{expression}'
    )
  })

  return updatedFormula
}

function findExpressions(latex) {
  let matches = []
  let stack = []
  let insideExpr = false
  let startIndex = 0

  for (let index = 0; index < latex.length; index++) {
    if (latex.slice(index, index + 6) === '\\expr{') {
      if (!insideExpr) {
        insideExpr = true
        startIndex = index + 6
      }
      stack.push('\\expr{')
      index += 5 // Move index to the end of \expr{
    } else if (latex[index] === '{' && insideExpr) {
      stack.push('{')
    } else if (latex[index] === '}' && insideExpr) {
      stack.pop()
      if (stack.length === 0) {
        insideExpr = false
        matches.push(latex.slice(startIndex, index))
      }
    }
  }
  return matches
}
