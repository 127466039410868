<template>
  <node-view-wrapper
    :as="isReadonly ? 'span' : 'button'"
    type="button"
    :tabindex="isReadonly ? null : -1"
    class="relative"
    :class="{
      '-my-[2px] -mx-1 px-1 rounded border-none hover:bg-gray-300 focus:bg-gray-300':
        !isReadonly,
      'bg-gray-300': !isReadonly && selected,
      'bg-transparent': !isReadonly && !selected,
      'inline-block -mx-2 my-[2px] p-2 rounded':
        isReadonly && variableType === 'image',
      'bg-pivot-purple-50':
        isReadonly &&
        variableType !== 'studentResponse' &&
        variableType !== 'image' &&
        !viewAsStudent,
      'bg-pivot-yellow-50':
        isReadonly && variableType === 'studentResponse' && !viewAsStudent,
      'text-ui-gray-800': toolTip
    }"
    @click="() => !isReadonly && onClick()"
    :data-testid="node.attrs.id"
    ref="popoverAnchorRef"
    @mouseover="openPopover"
    @mouseout="closePopover"
  >
    <template v-if="isReadonly">
      <base-popover
        v-if="
          (!viewAsStudent && variableType === 'studentResponse') ||
          !viewAsStudent
        "
        placement="top"
        :anchor-ref="popoverAnchorRef"
        :visible="popoverVisible"
      >
        {{ variablePopText }}</base-popover
      >
      <template v-if="variableType === 'image'">
        <img v-bind="imgAttrs" class="max-w-full" />
        <div
          v-if="!viewAsStudent"
          class="absolute top-4 left-4 w-12 h-12 rounded-full bg-pivot-purple-800 border-2 border-solid border-pivot-purple-50 flex items-center justify-center"
        >
          <icon class="text-white text" icon="shuffle" />
        </div>
      </template>

      <template v-else>
        <icon
          v-if="!viewAsStudent && variableType !== 'studentResponse'"
          class="text-pivot-purple-700 mr-2"
          icon="shuffle"
        />
        <icon
          v-if="!viewAsStudent && variableType === 'studentResponse'"
          icon="circle-info"
          class="text-pivot-yellow-700 mr-2"
        />
        <template v-if="formattedValue && typeof formattedValue === 'object'">
          {{ formattedValue.coefficient
          }}<template v-if="formattedValue.exponent">
            x 10<sup>{{ formattedValue.exponent }}</sup></template
          >
        </template>
        <template v-else>
          {{ formattedValue }}
        </template>
      </template>
    </template>
    <span
      v-else
      class="font-bold"
      :class="{
        'text-error-red-600': !variable,
        'text-pivot-teal-700': variable
      }"
    >
      [${{ variableName }}]
    </span>
  </node-view-wrapper>
</template>

<script setup>
import * as Vue from 'vue'
import { ref, computed } from 'vue'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import { formatNumber } from './format'
import { mapGetters } from 'src/shared/hooks/map-getters'
import { getVariable } from './Variable'

const props = defineProps(nodeViewProps)

const popoverAnchorRef = ref(null)
const popoverVisible = ref(false)
function closePopover() {
  popoverVisible.value = false
}
function openPopover() {
  popoverVisible.value = true
}

const isReadonly = computed(() => !props.editor.isEditable)
const viewAsStudent = computed(() => props.extension.storage.viewAsStudent)

const variable = computed(() =>
  getVariable(
    props.node.attrs.id,
    props.extension.storage.variableContext.variables
  )
)
const variableType = Vue.computed(() => {
  return variable.value?.variableType
})

const toolTip = Vue.computed(() => props.extension.storage.toolTip)
const variablePopText = Vue.computed(() => {
  if (variableType.value === 'studentResponse') {
    return `This value is the response to S${variable.value?.section}.Q${variable.value?.question}`
  } else {
    return `This ${variableType.value === 'image' ? 'image' : 'value'} is random for each student`
  }
})
const variableName = Vue.computed(
  () => variable.value?.name ?? props.node.attrs.id
)

const formattedValue = Vue.computed(() => {
  const v = variable.value
  if (!v) {
    return '???'
  } else if (typeof v.value === 'number') {
    return formatNumber(v.value, v)
  } else if (v.value === v.content) {
    return 'Student Response'
  } else if (typeof v.value !== 'undefined') {
    return v.value
  } else {
    return props.node.attrs.id
  }
})

const imgAttrs = Vue.computed(() => ({
  src: variable.value?.value?.url
    ? `//${PI_API_HOST}${variable.value.value.url}`
    : '',
  alt: variable.value?.value?.alt
}))

const onClick = () => {
  props.editor
    .chain()
    .setNodeSelection(props.getPos())
    .openVariableModal(props.node.attrs)
    .run()
}
</script>
