import type Client from './client'
import {
  ConfigureMFARequest,
  CreateInstructorRequest,
  ExchangeOAuthCodeRequest,
  GenerateMFAResponse,
  OAuthAuthorizeRequest,
  OAuthUserProfile,
  PostLoginRequestBody,
  PostLoginResponseBody,
  ResetPasswordRequest,
  SignUpOAuthRequest,
  StudentSignupRequest,
  ValidatePasswordResetRequest,
  ValidatePasswordResetResponse,
  OAuthAuthorizeResponse
} from '@pi/api-types'

export default class Auth {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async login(
    credentials: PostLoginRequestBody
  ): Promise<PostLoginResponseBody | undefined> {
    const requestBody = JSON.stringify(credentials)
    const { body } = await this.client.post<PostLoginResponseBody>({
      path: `/api/v3/user/login`,
      body: requestBody
    })
    return body
  }

  async logout(): Promise<void> {
    await this.client.post<void>({
      path: `/api/v3/user/logout`
    })
  }

  async requestPasswordReset(email: string): Promise<void> {
    await this.client.post<void>({
      path: `/api/v3/user/reset-password`,
      body: JSON.stringify({ email })
    })
  }

  async getPasswordResetToken(
    request: ValidatePasswordResetRequest
  ): Promise<ValidatePasswordResetResponse> {
    const { body } = await this.client.get<ValidatePasswordResetResponse>({
      path: `/api/v3/user/reset-password/${request.token}`
    })
    return body
  }

  async changePassword(request: ResetPasswordRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/user/reset-password/${request.token}`,
      body: JSON.stringify(request)
    })
  }

  async teacherSignup(request: CreateInstructorRequest): Promise<void> {
    const { body } = await this.client.post({
      path: `/api/v3/teacher-signup`,
      body: JSON.stringify(request)
    })
    return body
  }

  async studentSignup(request: StudentSignupRequest): Promise<void> {
    const { body } = await this.client.post({
      path: `/api/v3/students`,
      body: JSON.stringify(request)
    })
    return body
  }

  async oauthAuthorize(
    data: OAuthAuthorizeRequest
  ): Promise<OAuthAuthorizeResponse> {
    const { body } = await this.client.post<OAuthAuthorizeResponse>({
      path: '/api/v3/user/oauth/authorize',
      body: JSON.stringify(data)
    })
    return body
  }

  async exchangeOAuthCode(
    request: ExchangeOAuthCodeRequest
  ): Promise<OAuthUserProfile> {
    const { body } = await this.client.post<OAuthUserProfile>({
      path: `/api/v3/user/oauth/callback`,
      body: JSON.stringify(request)
    })
    return body
  }

  async loginOAuth(id?: string): Promise<PostLoginResponseBody> {
    const { body } = await this.client.post<PostLoginResponseBody>({
      path: `/api/v3/user/login/oauth`,
      body: JSON.stringify({ id })
    })
    return body
  }

  async signUpOauth(request: SignUpOAuthRequest): Promise<void> {
    const { body } = await this.client.post({
      path: `/api/v3/user/signup/oauth`,
      body: JSON.stringify(request)
    })
    return body
  }

  async getOAuthProfile(): Promise<OAuthUserProfile> {
    const { body } = await this.client.get<OAuthUserProfile>({
      path: '/api/v3/user/oauth/profile'
    })
    return body
  }

  async connectOAuthAccount(password?: string): Promise<void> {
    const { body } = await this.client.post({
      path: '/api/v3/user/oauth/connect',
      body: JSON.stringify({ password })
    })
    return body
  }

  async generateMFA(): Promise<GenerateMFAResponse> {
    const { body } = await this.client.post<GenerateMFAResponse>({
      path: '/api/v3/user/generate-mfa'
    })
    return body
  }

  async configureMFA(request: ConfigureMFARequest): Promise<void> {
    await this.client.post<void>({
      path: '/api/v3/user/configure-mfa',
      body: JSON.stringify(request)
    })
  }

  async verifyMFAToken(token: string): Promise<void> {
    await this.client.post({
      path: `/api/v3/user/verify-mfa-token`,
      body: JSON.stringify({ token })
    })
  }

  async chooseLoginProvider(provider: string): Promise<void> {
    await this.client.patch({
      path: `/api/v3/user/choose-login`,
      body: JSON.stringify({ provider })
    })
  }
}
